@import 'src/styles/variables';
@import 'src/styles/mixins';

.title {
  color: $black;
  @include text(12px, 700, 20px);
}

.link {
  display: flex;
  align-items: center;
  cursor: pointer;

  gap: 8px;

  @include vp-767 {
    margin: 18px 0;
    justify-content: space-between;
  }
}
