@import 'src/styles/mixins';
@import 'src/styles/variables';

.button {
  width: 40px;
  padding: 8px;
  border-radius: 24px;
  transition: all 0.3s;
  @include text-16;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  gap: 10px;

  cursor: pointer;
  font-family: $manrope;
  box-sizing: border-box;
  max-height: 36px;
  background-color: $light-gray;
  color: $black;

  &:hover {
    background-color: $primary;
    color: $white;

    span {
      transition: all 0.3s;
      color: $white;
    }

    .profile {
      &Svg path,
      &Svg circle {
        stroke: $white;
      }
    }
  }
}

.profile {
  padding: 8px 16px;
  gap: 10px;

  &Svg path,
  &Svg circle {
    stroke: $black;
    transition: all 0.3s;
  }
}
