@import 'src/styles/mixins';
@import 'src/styles/variables';

.button {
  width: fit-content;
  padding: 8px;
  border-radius: 24px;
  transition: all 0.3s;
  @include text-16;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;

  cursor: pointer;
  font-family: $manrope;
  box-sizing: border-box;
  max-height: 36px;
  background-color: $light-gray;
  color: $black;

  &:hover {
    background-color: $primary;
    color: $white;

    .burger path {
      fill: $white;
    }
  }
}

.burger path {
  fill: $black;
  transition: all 0.3s;
}
