@import 'src/styles/variables';
@import 'src/styles/mixins';

.social {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $gray;
  border-radius: 50px;
  padding: 9px;
  width: fit-content;
  cursor: pointer;
  opacity: 50%;
}

.title {
  @include text(12px, 400, 167%);
  color: $gray;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  column-gap: 34px;
  row-gap: 12px;

  @include vp-767 {
    flex-direction: column;
    align-items: start;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
