@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  position: relative;
  z-index: 10;

  @include vp-1024 {
    position: absolute;
    left: 16px;
  }
}

.button {
  padding: 12px 16px;
  border-radius: 24px;
  transition: all 0.3s;
  @include text(16px, 500, 22px);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  gap: 8px;
  cursor: pointer;
  font-family: $manrope;
  box-sizing: border-box;
  max-height: 36px;
  background-color: $light-gray;
  color: $black;

  @include vp-1024 {
    padding: 11px 16px;
  }

  &:hover {
    background-color: $primary;
    color: $white;

    @include vp-1024 {
      background-color: $light-gray;
      color: $black;
    }

    .locale {
      transition: all 0.3s;
      color: $white;

      @include vp-1024 {
        color: $black;
      }
    }

    .svg {
      path {
        transition: all 0.3s;
        stroke: $white;

        @include vp-1024 {
          stroke: $black;
        }
      }
    }
  }
}

.active {
  transition: all 0.3s;
  transform: rotate(180deg);
}

.locale {
  @include vp-1024 {
    @include text(14px, 500, 22px);
  }
}

.menu {
  position: absolute;
  left: 0;
  top: calc(100% + 20px);
  background-color: $light-gray;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include vp-1024 {
    top: calc(100% + 15px);
  }
}

.option {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    .optionText {
      color: $primary;
    }

    .optionBold {
      color: $primary;
    }
  }

  &Text {
    @include text(14px, 500, 20px);
    transition: all 0.3s;
  }

  &Bold {
    @include text(14px, 700, 20px);
    transition: all 0.3s;
  }

  &Active {
    cursor: default;

    .optionText {
      color: $primary;
    }

    .optionBold {
      color: $primary;
    }
  }
}
